<template>
    <v-content class="page_home">
        <HomeBody/>
    </v-content>
</template>

<script>
    import HomeBody from "@/components/Home_body";
    import {mapGetters,mapActions} from "vuex";
    import { getParameterByName } from '@/utils/utils';
    export default {
        name: "home",
        computed: {
            ...mapGetters({
                'getCustomerTypeTrk': 'params/getCustomerTypeTrk',
            }),
        },
        components: {
            HomeBody,
        },
        data() {
            return {
                idSession: sessionStorage.getItem('sessionId'),
            };
        },
        beforeMount(){
            window['tc_vars'] = {
                env_template: "AXA_PerNoi",
                env_work: process.env.VUE_APP_TRK_ENV,
                env_channel: channel,
                user_category: this.getCustomerTypeTrk,
                page_name: "Homepage",
                user_id: sessionStorage.getItem('sessionId'),
            };
        },
        head: {
            script: [
                {
                    type: 'text/javascript',
                    src: process.env.VUE_APP_URL_AXA1,
                },
                {
                    type: 'text/javascript',
                    inner: 'if (typeof tc_events_global === "function") { \n' +
                           '    tc_events_global(this, "virtual_page", {});\n' +
                           '}'
                },
            ],
            noscript: [{
                src: '//redirect4708.tagcommander.com/utils/noscript.php?id=1&amp;amp;mode=iframe',
                width: '1',
                height: '1',
                rel: 'noindex,nofollow',
            }],
        },
        methods: {
            ...mapActions({
                'actionClearQuotation': 'params/actionClearQuotation',
                'actionInsType': 'params/actionInsType',
                'actionCustomerType': 'params/actionCustomerType'
            }),
        },
        created() {
            this.actionInsType(null);
            this.actionCustomerType('');
            this.actionClearQuotation();
            const utmCampaign = getParameterByName('utm_campaign');
            const utmSource = getParameterByName('utm_source');

            if (utmCampaign) {
                sessionStorage.setItem('utm_campaign', utmCampaign); // The cookie will expire in 30 days
            }

            if (utmSource) {
                sessionStorage.setItem('utm_source', utmSource);
            }

        },

    };
</script>
